<template>
  <div>
    <h1>Paiement réussi !</h1>
    <p>Merci pour votre inscription.</p>
    <p>Un email de confirmation vous a été envoyé.</p>
  </div>
  <div class="actions">
    <router-link class="prevstep" to="/">Inscrire une autre personne</router-link>
  </div>
</template>

<script>
export default {
    props: {
      formData: {
        type: Object,
        required: true
      },
      events: {
        type: Object,
        required: true
      },
      updateDatasUser: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        
      };
    },
    created() {
      this.reinitDatas();
    },
    methods: {
      reinitDatas() {
        this.updateDatasUser({
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          section: '',
          address: '',
          postalCode: '',
          city: '',
          invoice: false,
          invoiceName: '',
          invoiceTVA: '',
          invoiceAddress: '',
          invoicePostalCode: '',
          invoiceCity: '',
          international: false,
          selectedEvents: []
        });
      },
    }
  };
</script>

<style>
  /* .done{
    pointer-events: none;
  } */
</style>